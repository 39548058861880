import Alpine from 'alpinejs'

export const initIntersectObserver = () => {
    Alpine.data('intersectObserver', () => ({
        entered: false,
        init() {
            const elementsWithDelay: NodeListOf<HTMLElement> = this.$el.querySelectorAll('[data-delay]');
            const elementsArray: HTMLElement[] = Array.from(elementsWithDelay);
            elementsArray.forEach((element: HTMLElement) => {
                if(element.hasAttribute('data-delay')) {
                    element.style.setProperty('--transition-delay', element.getAttribute('data-delay') + 's');
                }
            });
        },
        enter() {
            this.entered = true
        },
    }))
}
