import Splide from '@splidejs/splide'
import Alpine from 'alpinejs'

export const NavigationSlider = () => {
    Alpine.data('initNavigationSlider', () => ({
        init(){

            const previousBtn = document.querySelector('.navigation-slider__control-prev')
            const nextBtn =  document.querySelector('.navigation-slider__control-next')

            if(!previousBtn || !nextBtn) return

            var splide = new Splide( this.$el, {
                type   : 'slide',
                perPage: 5,
                perMove: 1,
                isNavigation: false,
                speed: window.touch ? 400 : 1200,
                easing: window.touch ? 'cubic-bezier(0.25, 1, 0.5, 1)' : 'cubic-bezier(0.8, 0, 0.2, 1)',
                pagination: false,
                gap: 30,
                arrows: false,
                breakpoints: {
                    1700: {
                        perPage: 5,
                    },
                    1400: {
                        perPage: 4.5,
                    },
                    1200: {
                        perPage: 3.5
                    },
                    900: {
                        perPage: 2.5,
                    },
                    600: {
                        perPage: 1,
                        padding: {
                            right: '20%',
                            left: '0',
                        },
                    }
                }
            } );

            splide.mount();

            previousBtn.addEventListener('click', () => {
                splide.go('<')
            })
            nextBtn.addEventListener('click', () => {
                splide.go('>')
            })

            splide.on('move', updateButtonStates);
            splide.on('mounted', updateButtonStates);
            splide.on('resize', updateButtonStates);
            splide.on('resized', updateButtonStates);

            function updateButtonStates() {
                const index = splide.index;
                const isStart = index === 0;
                const isEnd = index === splide.Components.Controller.getEnd();

                if(!previousBtn || !nextBtn) return

                previousBtn.disabled = isStart;
                nextBtn.disabled = isEnd;
            }

            updateButtonStates();

        }
    }))
}