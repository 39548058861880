import {Loader, LoaderOptions} from 'google-maps';

const initMap = async function initMap(){
    const latlng = { lat: 51.904990, lng: 4.376010 }
    // LoaderOptions doesn't contain the loading property, but we can use it here and it works
    const options: LoaderOptions = {'loading': 'async', 'libraries': ['marker']}
    const loader = new Loader('AIzaSyAA3r_99Aaj3-q2r6y8DM5JNqc_yX2wqao', options)
    const google = await loader.load()

    document.querySelectorAll('.maps-block__map').forEach(mapElement => {
      let map = new google.maps.Map(mapElement, {
        mapId: '9a4656129282b578',
        center: latlng,
        zoom: 13,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      })


      new google.maps.marker.AdvancedMarkerElement({
        position: latlng,
        map
      })
    })
}
  


export { initMap }