import Alpine from 'alpinejs'

export const initJSEssentials = () => {
    Alpine.data('jsEssentials', () => ({
        mouse: false,
        init() {
            //Detect touch
            this.initTouchDetect()
            //Detect if JS is present
            this.jsDetect()
        },
        initTouchDetect() {
            // Check for touch capabilities
            if ('ontouchstart' in document.documentElement) {
                this.$el.classList.add('touch')
                window.touch = true
            } else {
                window.touch = false
            }
        },
        onPointerMove(e: PointerEvent) {
            //Why is this undefined?
            if (!this.mouse && e.pointerType === 'mouse') {
                // User is using mouse after all
                this.mouse = true
                this.$el.classList.remove('touch')
                window.touch = false
            } else if (this.mouse && e.pointerType === 'touch') {
                // User is using touch (again)
                this.mouse = false
                this.$el.classList.add('touch')
                window.touch = true
            }
        },
        destroy() {
            // Clean up event listener when no longer needed
            this.$el.removeEventListener('pointermove', this.onPointerMove)
        },
        jsDetect() {
            this.$el.classList.remove('no-js')
        },
    }))
}
